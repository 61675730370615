import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';
import usStatesObj from 'datasets-us-states-abbr-names';


import 'react-table/react-table.css';

const usStatesOptions = Object.keys(usStatesObj).reduce((acc, abbr) => {
    acc.push({label: usStatesObj[abbr], value: abbr}, {label: abbr, value: abbr});
    return acc;
}, []);

class SecurityIncidents extends Component {
    constructor(props) {
        super(props)
        const initSortOrder = sessionStorage.getItem(`securityIncidents:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'occurrenceDate', desc: true},
            securityIncidents: [],
            securityIncidentsOpen: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false,
            isDownloadDropdownOpen: false                 
        };
    };

    getData = () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});

        fpRest.get('security-incidents')
        .then(res => {
            const securityIncidents = res.data;
            const securityIncidentsOpen = securityIncidents.filter(({statusId}) => statusId === 1);
            console.log(securityIncidents);
            this.setState({securityIncidents, securityIncidentsOpen, showLoader: false});
        })
        .catch(err => console.log(err));          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };    

    toggleDownloadDropdown = () => {
        this.setState({isDownloadDropdownOpen:!this.state.isDownloadDropdownOpen});
    };            

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    uploadSecurityIncidents = securityIncidents => {
        const getData = this.getData.bind(this);
        const policyPromises = securityIncidents.map(log => fpRest.post('security-incidents', {...log, active: 1}));
        Promise.all(policyPromises)
        .then(res => {
            getData();
            toast.success('Incident Uploaded');
        })
        .catch(err => { 
            if (err.response && err.response.data) {
                err.response.data.errors.forEach(err => {
                    toast.error(`import failed: ${err}`);
                });
                console.log(err.response.data);
            }
            else {
                console.log(err);
                toast.error(`Oops! Something didn't work right. Incidents weren't imported`);
            }
        });
    };

    printAll = () => {
        console.log('Print All');
    };

    downloadAllSecurityIncidents = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=securityIncidentsAll&format=csv`, `Incidents.csv`);  
            this.setState({showLoader: false, isLoadingReport: false});    
            if (res.message == 'No data found') toast.success('There are no Incidents');   
        }
        catch (err) {
            console.log(err);
        }
    };    

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, uploadSecurityIncidents, downloadAllSecurityIncidents, toggleDownloadDropdown, toggleInactive} = this;
        const {showLoader, initSortOrder, securityIncidents, securityIncidentsOpen, hideInactive, isImporterOpen, isImportDropdownOpen, isDownloadDropdownOpen} = this.state;
   
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title="Incidents" actionItems={[
                    {label: <Svg use="add" />, tip: 'New Incident', link: '/security-incidents/new'},
                    //{label: <Svg use="upload" />, tip: 'Upload Incident', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/securityIncidents-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown},
                    {label: <Svg use='download'/>, tip:'Download',
                    options: [
                        {label: 'Download All Incidents (CSV)', action: downloadAllSecurityIncidents}
                    ],
                    isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown},                                                
                ]} />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Open Incidents', value: securityIncidentsOpen.length},
                            {label: 'Hide Closed', value: hideInactive, editing: true, inputType: 'toggle', onChange: toggleInactive}
                        ]}
                    />
                </section>                
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={hideInactive ? securityIncidentsOpen : securityIncidents}
                            defaultSorted={[initSortOrder]}
                            columns={[            
                                {Header: 'id', accessor: 'id', show: false},
                                {
                                    Header: 'Occurrence Date',
                                    accessor: 'occurrenceDate',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'

                                },
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Category',
                                    accessor: 'category',
                                },
                                {
                                    Header: 'Type',
                                    accessor: 'type',
                                },
                                {
                                    show: hideInactive ? false : true,
                                    Header: 'Status',
                                    accessor: 'status',
                                    Filter: ({onChange}) => <DorpDownFilter options={[{label: 'Open', value: 'Open'}, {label: 'Closed', value: 'Closed'}]} action={onChange.bind(this)}/>            
                                }                                                                                                                                    
                            ]}
                            noDataText="No Logs Found"
                            onSortedChange={sort => sessionStorage.setItem(`securityIncidents:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && this.props.history.push(`/security-incidents/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                </section>
                
                <CsvImporter
                    open={isImporterOpen}
                    onRequestClose={closeImporter}
                    onRequestUpload={res => uploadSecurityIncidents(res)}
                    title="Map Incident Fields"
                    mappingOptions={[
                        {label: 'Company Name', value: 'name', required: true},
                        {label: 'Address 1', value: 'address1'},
                        {label: 'Address 2', value: 'address2'},
                        {label: 'City', value: 'city'},
                        {
                            label: 'State',
                            value: 'state',
                            allowedCellValues: [{value: '', label: ''}, ...usStatesOptions]                          
                        },
                        {label: 'Zip', value: 'zip'},
                        {label: 'Phone 1', value: 'phone1'},
                        {label: 'Phone 2', value: 'phone2'},
                        {label: 'Fax 1', value: 'fax1'},
                        {label: 'Fax 2', value: 'fax2'},
                        {label: 'Website', value: 'website'},
                        {
                            label: 'Type',
                            value: 'typeId',
                            allowedCellValues: [
                                {value: '', label: ''},
                                {value: 1, label: ''},
                                {value: 2, label: ''}
                            ]                       
                        },
                        {
                            label: 'Active',
                            value:'active',
                            required: true,
                            allowedCellValues: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'},
                                {value: true, label: 'True'},
                                {value: false, label: 'False'},
                                {value: true, label: '1'},
                                {value: false, label: '0'}                                                          
                            ]
                        }
                    ]}
                />
           </Loader>
        );
    };
}

export default withRouter(SecurityIncidents);