const settingsHeaderNav = page => [
    {label: 'Company', link: '/settings/company', active: page === 'company'},
    {label: 'Users', link: '/settings/users', active: page === 'users'},
    {label: 'Departments & Roles', link: '/settings/job-roles', active: page === 'jobRoles'},
    {label: 'Assets', link: '/settings/assets', active: page === 'assets'},
    {label: 'Maintenance Logs', link: '/settings/maintenance-logs', active: page === 'maintenance'},
    {label: 'Incidents', link: '/settings/security-incidents', active: page === 'incidents'},
    {label: 'Policies', link: '/settings/policies', active: page === 'policies'}, 
    {label: 'Contractors', link: '/settings/business-associates', active: page === 'associateServices'}, 
];

export default settingsHeaderNav;